<template>
  <v-btn
    v-if="!warning"
    class="elevation-0 msg-bg"
    :color="color"
    @click.prevent="close"
    >{{ text }}
  </v-btn>
  <v-layout
    v-else
    :style="{ backgroundColor: color }"
    :class="['msg-bg warn primary--text']"
    :color="color"
    align-center
  >
    <v-layout @click.prevent="close" align-center>
      <v-icon color="#ff9400" class="mr-2">mdi-alert-circle</v-icon>
      {{ text }}
      <v-spacer></v-spacer>
    </v-layout>
    <slot name="icon"></slot>
  </v-layout>
</template>

<script>
export default {
  props: {
    type: {},
    text: {},
    warning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      if (this.warning) {
        return "#f7dbb5";
      } else if (this.type == 1) {
        return "error";
      } else {
        return "success";
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.msg-bg {
  height: 46px !important;

  &.warn {
    border-radius: 8px;
    font-size: 12px;
    padding: 12px;
    height: 50px !important;
  }
}
</style>